  <div class="favourite">
    <div class="row">
      <div class="col-12 customOverflow">
        <div class="row">
          <div class="col-12">
            <div [ngClass]="{'header': translate.is_arabic, 'headerEn': !translate.is_arabic}">
              <p>{{translate.data.shareCar.yourCarvan}}</p>
            </div>
            <div class="body ">
              <div class="container">
                <div class="noData" *ngIf="cars?.length == 0">
                  <h3>{{translate.data.shareCar.noCarvan}}</h3>
                  <button>{{translate.data.profile.update}}</button>
                </div>
                <div class="karavan-result" >
                  <app-karavan></app-karavan>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
<router-outlet></router-outlet>


import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import { PagesService} from '../../../pages/pages.service';
import { SharedService } from 'src/app/shared/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from 'src/app/shared/translation.service';


@Component({
  selector: 'app-profile-share-your-car',
  templateUrl: './profile-share-your-car.component.html',
  styleUrls: ['./profile-share-your-car.component.scss']
})
export class ProfileShareYourCarComponent implements OnInit {

    // cars data
    cars:any = [];

  constructor(
    private pagesService: PagesService,
    public translate: TranslationService,
    private route: ActivatedRoute,
    private router: Router,
    public sharedService: SharedService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    //get user carvans
    this.spinner.show();
    this.pagesService.getUserCarvans().subscribe(
      (response: any) => {
        this.cars = {resCarvans:response.data}
        if(this.cars == null){this.cars = []}
        console.log("favourite carvans",this.cars);
        this.pagesService.karavans.next(this.cars);
         this.spinner.hide();
      },
      (error:any) => {
        console.log(error);
        this.spinner.hide();
      }
    )
   }

}

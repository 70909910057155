import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileShareYourCarRoutingModule } from './profile-share-your-car-routing.module';
import { ProfileShareYourCarComponent } from './profile-share-your-car.component';
import { PagesSharedModule } from '../../pagesShared.module';


@NgModule({
  declarations: [ProfileShareYourCarComponent],
  imports: [
    CommonModule,
    ProfileShareYourCarRoutingModule,
    PagesSharedModule
  ]
})
export class ProfileShareYourCarModule { }
